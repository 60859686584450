import * as CryptoJS from "crypto-js";
import moment from "moment";
import { DataConstant } from "../app/constants/dataConstant";
import _ from "lodash";
import filter from "lodash.filter";
import translations from "../app/constants/en.json";
import { UrlConstant } from "../app/constants";

export const EncryptText = (text) => {
  const strKey = DataConstant.encryptDecryptKey;
  const key = CryptoJS.enc.Utf8.parse(strKey);
  const iv = CryptoJS.enc.Utf8.parse(strKey);
  const encdata = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
    keySize: 128 / 8,
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encdata.toString();
};

export const DecryptText = (text) => {
  const strKey = DataConstant.encryptDecryptKey;
  const key = CryptoJS.enc.Utf8.parse(strKey);
  const iv = CryptoJS.enc.Utf8.parse(strKey);
  const decrypted = CryptoJS.AES.decrypt(text, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const validateTwoDecimalOnly = (e, places = 3) => {
  let t = e.target.value.toString();
  e.target.value = t.indexOf(".") >= 0
    ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), places)
    : t
};

export const validateNoDecimal = (e) => {
  ignoreSignInNumber(e);
  if (e.keyCode === 190 || e.keyCode === 110) {
    e.preventDefault();
    return false;
  }
};

export const ignoreSignInNumber = (e) => {
  let kc = e.keycode || e.which;
  if (kc === 107 || kc === 109 || kc === 189 || kc === 187) {
    e.preventDefault();
    return false;
  } else return true;
};

export const validateOnlyNumber = (e) => {
  var x = parseInt(e.which || e.keycode);
  if (
    (x >= 48 && x <= 57) ||
    (x >= 96 && x <= 105) ||
    x === 8 ||
    x === 37 ||
    x === 39 ||
    x === 9 || x === 46
  ) {
    return true;
  } else {
    e.preventDefault();
    return false;
  }
};

export const validateOnlyNumberWithDot = (e) => {
  var x = parseInt(e.which || e.keycode);
  if (
    (x >= 48 && x <= 57) ||
    (x >= 96 && x <= 105) ||
    x === 8 ||
    x === 37 ||
    x === 39 ||
    x === 9 || x === 46 || x === 110
  ) {
    return true;
  } else {
    e.preventDefault();
    return false;
  }
};

export const handleEmptySpace = (e) => {
  let t = e.target.value.trim();
  e.target.value = t ? e.target.value : null;
};

export const assignMomentDateTime = (dateTime, browser) => {

  switch (browser?.browserPlatform) {
    case DataConstant.browserPlatform.MacIntel:
      return moment(String(dateTime), DataConstant.dateFormats.mmddyyyy).format(
        DataConstant.dateFormats.ddmmyyyy
      );

    default:
      return moment(String(dateTime)).format(
        DataConstant.dateFormats.ddmmyyyy
      );
  }

}
export const setDateFormat = (date) => {
  return moment(String(date)).format(
    DataConstant.dateFormats.mmmm_ddyy
  );

}
export const setMomentDateTime = (dateTime) => {
  if (window.navigator.platform.toLowerCase().includes('win') && (typeof InstallTrigger !== 'undefined') === false) {
    return moment(dateTime).format(
      DataConstant.dateFormats.ddmmyyyy
    )
  }
  else {
    if (moment(dateTime, DataConstant.dateFormats.ddmmyyyy).isValid()) {
      return moment(String(dateTime), DataConstant.dateFormats.ddmmyyyy).format(DataConstant.dateFormats.ddmmyyyy);
    }
    else {
      return moment(String(dateTime), "").format(DataConstant.dateFormats.ddmmyyyy);
    }
  }
}


export const IsValidDate = (date) => {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    // it is a date
    if (isNaN(date)) { // d.getTime() or d.valueOf() will also work
      // date object is not valid
      return false;
    } else {
      // date object is valid
      return true;
    }
  } else {
    // not a date object
    return false;
  }
}

export const subtractMonths = (numOfMonths, date = new Date()) => {
  date.setMonth(date.getMonth() - numOfMonths);
  return date;
}
const avoidingDuplicateMergeArray = (array1, array2) => {
  let mergeArray = array1.concat(array2);
  mergeArray = [...new Set([...array1, ...array2])];
  return mergeArray;
};

export const SearchListFunction = (query, data, queryParameter) => {
  const formattedQuery = _.toLower(query);
  const filteredData = filter(data, item => {
    return contains(item, formattedQuery, queryParameter);
  });
  return filteredData;
};

const contains = (item, query, queryParameter) => {

  const result = queryParameter?.map(qi => {
    if ((item !== null || item !== undefined) && _.toLower(item[qi]).includes(query)) {
      return true;
    }
    else {
      return false;
    }
  });

  return result.includes(true) ? true : false;
};


export const getLocationInfo = (results) => {
  let city = "", zipcode = [], addressJson = {};
  addressJson.fullAddress = results?.length > 0 ? results[0]?.formatted_address : null;
  for (let i = 0; i < results?.length; i++) {
    let validAddress = false;
    if (results[i]?.types?.length > 0 && (results[i]?.types?.includes("street_address") || results[i]?.types?.includes("premise") || results[i]?.types?.includes("subpremise") || results[i]?.types?.includes("establishment"))) {
      validAddress = true;
    }
    for (var j = 0; j < results[i]?.address_components?.length; j++) {
      var types = results[i]?.address_components[j]?.types.join(",");
      if (types === "street_number") {
        addressJson.street_number = results[i]?.address_components[j]?.long_name;
      }
      if (types === "route" || types === "point_of_interest,establishment") {
        addressJson.route = results[i]?.address_components[j]?.long_name;
      }
      if (types === "sublocality,political" || types === "locality,political" || types === "neighborhood,political" || types === "administrative_area_level_3,political") {
        addressJson.city = (city === "" || types === "locality,political") ? results[i]?.address_components[j]?.long_name : city;
      }
      if (types === "administrative_area_level_1,political") {
        addressJson.state = results[i]?.address_components[j]?.short_name;
      }
      if (types === "postal_code" || types === "postal_code_prefix,postal_code") {
        let currentZipCode = results[i]?.address_components[j]?.long_name;
        if (!_.isEmpty(currentZipCode) || !_.isUndefined(currentZipCode)) {
          zipcode = avoidingDuplicateMergeArray(zipcode, [currentZipCode]);
        }
        addressJson.zipcode = zipcode?.toString();
      }
      if (types === "country,political") {
        addressJson.country = results[i]?.address_components[j]?.long_name;
      }
    }
    addressJson.validAddress = validAddress;
  }
  return addressJson;
};

export const timeSlotList = (entities) => {
  let array = [];
  let spList = _.orderBy(entities, ["workingDateTime"], ["asc"]);
  array = _.chain(spList)
    // Group the elements of Array based on `workingDateTime` property
    .groupBy("workingDate")
    // `key` is group's name (workingDateTime), `value` is the array of objects
    .map((value, key) => {
      // let [v] = value;
      return ({
        workingDateTime: key,
        data: _.map(
          _.groupBy(value, (data) => {
            return data["userDetailId"];
          }),
          (slotRecord) => {
            let [otherDataofSP] = slotRecord;
            return {
              ...otherDataofSP,
              slots: _.map(slotRecord, (spData) => { return { slotName: moment(spData["workingTimeFormat"], "HH:mm").format("hh:mm A"), slotTime: moment(spData["workingTimeFormat"], "HH:mm").format("HH:mm:ss") } })
            };
          })
      });
    })
    .value();
  return array;
};

export const slotList = (entities) => {
  let array = [];
  let spList = _.orderBy(entities, ["workingDateTime"], ["asc"]);
  array = _.chain(spList)
    .map((spData, key) => {
      // let [v] = value;
      return { slotName: moment(spData["workingTimeFormat"], "HH:mm").format("hh:mm A"), slotTime: moment(spData["workingTimeFormat"], "HH:mm").format("HH:mm:ss") }
    }).value();

  console.log("Other sp List", array);
  return array;
};


export const endOfDate = (m, n, unit) => {
  const units = [
    "year",
    "month",
    "hour",
    "minute",
    "second",
    "millisecond",
  ];
  const pos = units.indexOf(unit);
  if (pos === -1) {
    throw new Error("Unsupported unit");
  }
  for (let i = pos + 1; i < units.length; i++) {
    m.set(units[i], units[i] === "millisecond" ? 999 : 59);
  }
  m.set(unit, Math.floor(m.get(unit) / n) * n + n - 1);

  return m?.add("1", "S");
};

export const formatAddressString = (addressLine1, addressLine2, city, state, zip, country, name, googleApi) => {
  let addressLine = [];
  if (googleApi) {
    addressLine1 && addressLine.push(`${addressLine1}`);
    addressLine2 && addressLine.push(`${addressLine2}`);
    city && zip && addressLine.push(`${city}-${zip}`);
    state && addressLine.push(`${state}`);
    country && addressLine.push(`${country}`);
  } else {
    name && addressLine.push(`${name}`);
    addressLine1 && addressLine.push(`${addressLine1}`);
    addressLine2 && addressLine.push(` ${addressLine2}`);
    city && addressLine.push(` ${city}`);
    state && addressLine.push(` ${state}`);
    country && addressLine.push(` ${country}${zip ? "" : "."}`);
    zip && addressLine.push(` ${zip}.`);
  }
  addressLine = _.without(addressLine, undefined, null, "");
  return addressLine?.toString();
};

export const IsValidString = (value) => {
  let isvalid = true;
  if (value === null || value === undefined || value === '') {
    isvalid = false;
  }
  return isvalid;
};
export const checkDateTimePassed = (utcDate) => {
  return (moment().isAfter(moment(utcDate)));
};

export const returnToken = (tab = "") => {
  if (tab !== null && tab !== "") {
    return `/widget/${tab}`;
  } else {
    return `/widget`;
  }
};

export const returnConsultationPage = (tab = "") => {
  if (tab !== null && tab !== "") {
    return `/widget/consultation/${tab}`;
  } else {
    return `/widget/consultation`;
  }
};

export const returnBookingDetailPage = () => {  
    return `/widget/booking-detail/`; 
};

export const returnBookingCancelPage = () => {  
  return `/widget/booking-cancel/`; 
};

export const returnDocumentViewEditPage = () => {  
  return `/widget/viewEdit-Documents/`; 
};


export const calculatetime = (services) => {
    var serviceTotalDuration = 0;
    var totalDuration = 0;
    var totalBufferTime = 0;
    _.each(services, function (item) {
        serviceTotalDuration += item.bufferTime + item.duration + (item.perPersonAdditionalTime * (item.qty - 1));
        totalDuration += item.duration;
        totalBufferTime += item.bufferTime;
    });
    return {
        serviceTotalDuration: serviceTotalDuration, totalDuration: totalDuration, totalBufferTime: totalBufferTime
    };
};

export const gettotalOfServices = (services, addon) => {
  let combinedArray = [...services, ...addon];
  let total = combinedArray?.reduce((a, b) => +a + +b?.total, 0);
  return total;
};

export const getServiceCount = (services, addon) => {
  let serviceCount = 0;
  if ((services !== undefined && services?.length > 0) || (addon !== undefined && addon?.length > 0)) {
    if(addon !== undefined && addon?.length > 0)
    {
       serviceCount = [...services, ...addon]?.reduce((a, b) => +a + +b?.qty, 0);
    }
    else
    {
       serviceCount = services?.reduce((a, b) => +a + +b?.qty, 0);
    }
  }
  return serviceCount;
}

export const getRequiredConsulatationServices = (services, addon) => {
  let serviceAndAddOnList = [];
  if (services && services.length > 0) {
    services.filter(item => item?.isRequiredTelemedicine === true && item).map(service => {
      serviceAndAddOnList = [...serviceAndAddOnList, service];
    });
  }

  if (addon && addon.length > 0) {
    addon.filter(item => item?.isRequiredTelemedicine === true && item).map(addon => {
      serviceAndAddOnList = [...serviceAndAddOnList, addon];
    });
  }
  return serviceAndAddOnList;

};
export const getOccurrence = (array, value) => {
  var count = 0;
  array.forEach((v) => (v === value && count++));
  return count;
};
export const savePreBookingArrayToString = (data) => {
  let formatedString = "";
  let arrangeData = _.orderBy(data, ["sequenceNo"], ["asc"]);
  arrangeData?.length > 0 && arrangeData.map((item) => {
    formatedString = formatedString + item?.discountType + ",";
  });
  return formatedString;
};
export const getServiceTotal = (data) => {
  return data?.reduce((a, b) => +a + +b?.servicePrice, 0);
};
export const getServiceAddOnList = (selectedServiceList, selectedAddOnList) => {
  let allServices = [];
  selectedServiceList?.length > 0 && selectedServiceList.map((item) => {
    for (let i = 0; i < item?.qty; i++) {
      let object = { ...item, qty: 1, considerMembership: i < item?.considerMembership ? 1 : 0 };
      allServices = [...allServices, object];
    }
  });
  selectedAddOnList != null && selectedAddOnList?.length > 0 && selectedAddOnList.map((item) => {
    for (let i = 0; i < item?.qty; i++) {
      let object = { ...item, qty: 1 };
      allServices = [...allServices, object];
    }
  });
  return allServices;
};
export const discountCalculation = (data, isFromMembership, totalAmount) => {

  let paymentDetails = data?.membershipDiscountType;
  let hasData = (paymentDetails === DataConstant.membershipDiscountType.Credit || paymentDetails === DataConstant.membershipDiscountType.Service);

  let iscreditDiscountAmount = data?.creditDiscountAmount > 0;
  let isgroupDiscountAmount = data?.groupDiscountAmount > 0;
  let iscouponDiscountAmount = data?.couponDiscountAmount > 0;
  let isrewardClubDiscountAmount = data?.rewardClubDiscountAmount > 0;
  let total = totalAmount;

  if (iscreditDiscountAmount && !isFromMembership && !hasData) {
    total = total - data?.creditDiscountAmount;
  }
  if (isgroupDiscountAmount) {
    total = total - data?.groupDiscountAmount;
  }
  if (iscouponDiscountAmount) {
    total = total - data?.couponDiscountAmount;
  }
  if (isrewardClubDiscountAmount) {
    total = total - data?.rewardClubDiscountAmount;
  }
  return total;
};
export const sequenceManger = (data, isAdd, isRemove, key) => {
  let isFound = false;
  let newData = data;
  newData.length > 0 && newData.map((item) => {
    if (item === key) {
      isFound = true;
    }
  });
  if (key?.includes(DataConstant.sequenceData.Credit) || key?.includes(DataConstant.sequenceData.Coupon)) {
    if (isAdd) {
      if (!isFound) {
        newData = [...newData, key];
      }
    } else if (isRemove) {
      newData = newData?.filter(i => i !== key);
    }
  }
  return newData;
};
export const getSequence = (data) => {
  let newData = [];
  data.length > 0 && data.map((item) => {
    if (item?.includes(DataConstant.sequenceData.Credit)) {
      newData = [...newData, DataConstant.sequenceData.Credit];
    } else if (item?.includes(DataConstant.sequenceData.Coupon)) {
      newData = [...newData, DataConstant.sequenceData.Coupon];
    }
  });
  return newData;
};
export const arrayToString = (data) => {
  let formatedString = "";
  if (data && data.length > 0) {
    data.map((item) => {
      formatedString = formatedString + item + ",";
    });
  }
  return formatedString;
};
export const findFieldByLabel = (data, id, key) => {
  if (data && !_.isEmpty(data)) {
    let el = data?.find(el => el[key] === id); // Possibly returns `undefined`
    return el && el; // so check result is truthy and extract `id`
  } else {
    return {};
  }
};
export const getCurrentDate = (date) => {
  let rountDate = endOfDate(moment(date), DataConstant.bookingMinInterval, "minute");
  return new Date(rountDate);
};
export const findMessage = (message) => {
  if (translations && message.includes(DataConstant.ServerValidation)) {
    let msgValue = translations;
    let msg = message && !_.isEmpty(message) && message?.split(".") ? message?.split(".") : [];
    if (msg && msg.length > 0) {
      msg.map((item) => {
        if (msgValue[item]) {
          msgValue = msgValue[item];
        }
      });
      return msgValue !== translations ? msgValue : "";
    }
    else
      return message;
  } else {
    return message;
  }
};
export const bookingStringToArray = (data) => {
	let array = [];
	if (data && !_.isEmpty(data)) {
      array = replaceAll(data, " ", "")?.split(",");
    }
	return array;
};
export const replaceAll = (data, stringToReplace, stringWithReplace) => {
	return data?.split(stringToReplace)?.join(stringWithReplace);
};
export const convertStringToBoolean = (value) => {
  return value !== null ? (value === "true" ? true : false) : null;
};
export const convertBooleanToString = (value) => {
  return value !== null ? (value === true ? "true" : "false") : null;
};
export const changeDateFormat = (value) => {
  return value !== null
    ? moment(value).format(DataConstant.dateFormats.yyyyMMDD)
    : null;
};
export const getNewDate = () => {
  return moment(new Date()).format(
    DataConstant.dateFormats.yyyyMMDD
  );
};

export const getLocalDateTime = (date, time) => {
  if (typeof time === "string") {
    return moment(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss").format("h:mm A");
  } else {
    return moment(
      `${date} ${time?.hours}:${time?.minutes}:${time?.seconds}`,
      "YYYY-MM-DD HH:mm:ss"
    ).format("h:mm A");
  }
};

export const getTimeZoneDetailDataByUTC = (data, UTCKey, dateFormat, timeFormat) => {
	return data ? {
		...data,
		bookingTimeZoneDate: data[UTCKey]
			? moment(`${data[UTCKey]}Z`)?.format(dateFormat ? dateFormat : "YYYY-MM-DD")
			: moment()?.format("YYYY-MM-DD"),
		bookingTimeZoneTime: data[UTCKey]
			? moment(`${data[UTCKey]}Z`)?.format(timeFormat ? timeFormat : "HH:mm:ss")
			: moment()?.format("HH:mm:ss")
	} : null;
};

export const getConsultationBookingDate = (date) => {
  return moment(date).format(DataConstant.dateFormats.ddd_MMMM_D_yy)
};

export const getRequiredServiceAddons = (list) => {
  return list?.map((item) => item?.serviceId)?.join(",");
};

export const openFileInNewWindow = (fileObject) =>{
  if(fileObject)
  {   
    let reader = new FileReader();  
    const newWindowObj = window.open();
    newWindowObj.document.write('<html><head><title>File Preview</title></head><body>');

    switch (fileObject.type) {
      case "application/pdf":        
        reader.onload = function (event) {    
          newWindowObj.document.write(`<iframe src="${event.target.result}" width="100%" height="100%"></iframe>`); 
        };          
      break;

      case "image/png":          
        reader.onload = function (event) {              
          newWindowObj.document.write(`<img src="${event.target.result}" alt="File Preview" />`);   
        };          
      break;
     
      default:
        return ;
    }  
    newWindowObj.document.write('</body></html>');  
    reader.readAsDataURL(fileObject);    
  }    
}

export const getTimeZoneDateAndTimeArray = (array, UTCKey) => {
  return array?.length > 0
    ? array?.map((item) => {
        return {
          ...item,
          bookingTimeZoneDate: moment(`${item[UTCKey]}Z`)?.format(
            "YYYY-MM-DD"
          ),
          bookingTimeZoneTime: moment(`${item[UTCKey]}Z`)?.format("HH:mm:ss"),
        };
      })
    : [];
};

export const groupDataByDateWise = (data,columnName) =>{
  const ListData = getTimeZoneDateAndTimeArray(
    data,
    columnName
  );

  let listDataAfterGrouping = _.chain(ListData)
  .groupBy("bookingTimeZoneDate")
  .map((value, key) => ({ date: key, data: value }))
  .value();

  return listDataAfterGrouping;
}

export const addKeyIndexToListData = (data) =>{
  return data.map((entity, index) => ({
    ...entity,
    uniqueKey: `key-${index}`,
  }));
}

export const getValueFromArrayLabelsWise = (id, array, idLabel, valueLabel) => {
    return array?.length > 0
        ? array?.find(item => Number(item[idLabel]) === id) ? array?.find(item => Number(item[idLabel]) === id)[valueLabel] : null
        : null;
};

export const IsValidNumber = (value) => {
  let isvalid = true;
  if (value === null || value === undefined || value === 0) {
    isvalid = false;
  }
  return isvalid;
};

export const getConsultPath = () => {  
  return `/widget/consultation/`; 
};

export const IsValidList = (list) => {
  let isValid = true;
  if (list === null || list === undefined || list.length === 0) {
    isValid = false;
  }
  return isValid;
};

export const isRequiredTelemedicineServices = (list) => {
  let bookingServiceList = [];
  if (list && list.length > 0) {
      bookingServiceList = list?.filter(item => item?.isRequiredTelemedicine === true && item);
  }
  return bookingServiceList;
};


export const  getServiceAndAddonsArray = (isCallOutService,selectedServiceList,selectedAddOnList) => {
  let addOnList = selectedAddOnList ? selectedAddOnList : [];
  let combinedArray = [...selectedServiceList, ...addOnList];
  let newArrayWithInsurance = [];
  // let insuranceCoveredSericesId = [];
  // insuranceCoveredSerices?.length > 0 && insuranceCoveredSerices?.map((item) => {
  //   if (!insuranceCoveredSericesId.includes(item?.serviceId)) {
  //     insuranceCoveredSericesId = [...insuranceCoveredSericesId, item?.serviceId];
  //   }
  // });
  //let selectedComplimentaryArray = _.map(AppState?.Home?.clientAccquireComplimentaryDetail?.selectedComplimentaryServices, "id");
  let containData = [];
  let containId = [];
  let selectedList = [];

  if (selectedServiceList?.length > 0) {
    selectedServiceList.map((item) => {
      if (item?.rewardClubService >= 1) {
        selectedList = [...selectedList, item?.serviceId];
      }
    });
  }

  combinedArray?.length > 0 && combinedArray.map((item) => {
    let rewardCount = getOccurrence(selectedList, item?.serviceId);
    if (!containId?.includes(item?.serviceId)) {
      let object = { ...item, rewardClubServiceCount: rewardCount };
      containData = [...containData, object];
      containId = [...containId, item?.serviceId];
    }
  });

  containData.map((item) => {
    //let insuranceCoveredObject = findFieldByLabel(insuranceCoveredSerices, item?.id, "serviceId");
    for (var i = 0; i < item?.qty; i++) {
      //let isComplimentaryIV = _.includes(selectedComplimentaryArray, item?.id);
      //isComplimentaryIV && selectedComplimentaryArray?.splice(_.indexOf(selectedComplimentaryArray, item?.id), 1);
      let object = {
        "serviceId": item?.serviceId,
        "noOfPatient": 1,
        "servicePrice": isCallOutService ? item.callOutBookingPrice : item.facilityBookingPrice,
        "duration": item?.duration,
        "bufferTime": item?.bufferTime,
        "perPersonAdditionalTime": item?.perPersonAdditionalTime,
        "originalNoOfPatient": item?.qty,
        "isComplimentaryIV": false,//isComplimentaryIV,
        "isRequiredTelemedicine": item?.isRequiredTelemedicine,
        "isServiceConsideredForInsurance": false,//insuranceCoveredSericesId.includes(item?.id) && i < insuranceCoveredObject?.selectedCount, // na
        "noOfServiceForInsurance": 0,//insuranceCoveredSericesId.includes(item?.id) ? i < insuranceCoveredObject?.selectedCount ? 1 : 0 : 0, //na
        "isMembershipService": i < item?.considerMembership,
        "serviceType": item?.serviceType === DataConstant.SERVICE ? 1 : 2,
        "isRewardClubIV": selectedList.includes(item?.serviceId) && i < item?.rewardClubServiceCount
      };
      newArrayWithInsurance = [...newArrayWithInsurance, object];
    }

  });
  return newArrayWithInsurance;
}

export const convertUtcDateToTimeZoneDate = (date) => {
	return moment(`${date}Z`);
};

export const getTotalServiceConsultAmount = (selectedServiceList) => {
  const serviceData = selectedServiceList || [];
  let total = 0;

  if (serviceData.length !== 0) {
    serviceData.forEach(item => {
      total += item?.commissionAmount || 0;
    });
  }
  return total;
};

export function HasRequiredTelemedicineService(selectedServiceList) {
    if (selectedServiceList && selectedServiceList.length > 0) {
        return selectedServiceList.some(
            (service) => service.isRequiredTelemedicine
        );
    }
    return false;
}

export const getserviceConsultForBookingList = (list) => {
  var serviceConsultForBookingList  = [];
  if(IsValidList(list))
  {
      serviceConsultForBookingList = list?.map((item, key) => {
          const serviceConsultForBooking = {
            clientDetailId : item?.clientDetailId,
            isServiceConsultRequired : item?.isServiceConsultRequired, 
            hideServiceConsultViewBlock : item?.hideServiceConsultViewBlock,
            hasRejectedServiceInConsultation : item?.hasRejectedServiceInConsultation,
            serviceId : item?.serviceId,
            serviceName : item?.serviceName,
            serviceConsultsForBookingResponse : {
              userServiceConsultRequestId : item?.serviceConsultsForBookingResponse?.userServiceConsultRequestId,
              scheduleDateTimeInUTC : item?.serviceConsultsForBookingResponse?.scheduleDateTimeInUTC

            }
          };
        return serviceConsultForBooking;
      });
  }
  return serviceConsultForBookingList;
};

export const returnConsultationDetailPage = () => {  
  return `/widget/consultation-detail/`; 
};

export const getAssignServiceDetailList = (serviceWiseConsultationFeesList,resceduleConsultationServiceList) => {
  let assignServiceDetails = serviceWiseConsultationFeesList?.length > 0 ? serviceWiseConsultationFeesList : [];
  let serviceDetails = [];

  assignServiceDetails.map((item, index) => {
    let object = {};
    let matchData = resceduleConsultationServiceList?.find((j, i) => `${item?.serviceId}_${index}` == `${j?.serviceId}_${i}`);
    object = {
      serviceId: item?.serviceId,
      commissionLevel: item?.commissionLevel,
      commissionValueType: item?.commissionValueType,
      commissionValue: item?.commissionValue,
      commissionValueAmount: item?.commissionValueAmount,
      userServiceConsultRequestServiceId: matchData ? matchData?.userServiceConsultRequestServiceId : item?.userServiceConsultRequestServiceId
    };
    serviceDetails.push(object);
  });
  return serviceDetails;
};

export const getTimeDiff = (start, end) => {
    return moment.duration(moment(end, DataConstant.dateFormats.hhmmsssmalla).diff(moment(start, DataConstant.dateFormats.hhmmsssmalla)));
};

export const getLocationAddressByLatLong = async (lat, long) => {
  if (lat && long) {
    const response = await fetch(UrlConstant.widget.getLocationAddressByLatLong(lat, long));
    let res = await response?.json();
    res = getLocationInfo(res?.results);
    return res;
  }
};

export const getZipCodeByLatLong = async (lat, long) => {
  if (lat && long) {
    var res = await getLocationAddressByLatLong(lat,long);
    let zipCode = res ? res?.zipcode?.toString() : null;
    return zipCode;
  } else {
    return null;
  }
};

export const filterAndFlattenConsultationData = (consultationResponseData) => {
  if (!consultationResponseData || !Array.isArray(consultationResponseData)) {
    return [];
  }

  return consultationResponseData.flatMap((item) => {
    if (item?.isServiceConsultRequired === true && !item?.serviceConsultsForBookingResponse) {
      return item;
    } else {
      return [];
    }
  });
};

export const generateRandomNumber = (value = "") =>{
  return value + Math.random() * 1000 + 1.55
}

export const IsNullorUndefined = (value) => {
  let isvalid = false;
  if (value === null || value === undefined) {
    isvalid = true;
  }
  return isvalid;
};
