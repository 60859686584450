/*eslint-disable no-useless-escape*/
export const DataConstant = {
  encryptDecryptKey: "fl!23net@%$$2!@#",
  defaultSorted: [{ dataField: "id", order: "asc" }],
  sizePerPageList: [
    { text: "50", value: 50 },
    { text: "100", value: 100 },
  ],
  pagingInfo: {
    searchColumn: {
      columnName: "",
      searchString: "",
      columnDataType: "",
    },
    sortOrder: "desc",
    sortField: "",
    page: 1,
    itemsPerPage: 50,
    searchText: "",
  },
  apiResponseStatus: {
    success: "Success",
    WARNING: "warning",
    OK: "ok",
    ERROR: "Error",
    INTERNAL: "Internal",
    ALREADYEXISTS: "AlreadyExists",
    NOTFOUND: "NotFound",
  },
  commonYesNoDropDownList: [
    { label: "No", value: false },
    { label: "Yes", value: true },
  ],
  commonStatus: [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "InActive" },
  ],
  userTypeUrlConstant: {
    adminStatus: "Admin",
    serviceProviderStatus: "Service Provider",
    hpsStandalone: "Standalone HCP",
    hpsBusiness: "HCP",
    medicalDirectorStatus: "MD",
    receptionistStatus: "Receptionist",
    affiliateStatus: "Affiliate",
    clientStatus: "Client",
  },
  whiteLabelNames: {
    telemedicine: { name: "Telemedicine" },
    dripbar: { name: "THE DRIPBaR" },
    hydreight: { name: "Hydreight" },
    milTeste: { name: "MilTestes" },
    metaVet: { name: "metavet" },
    vsdigital: { name: "VSDigital" },
  },
  dateFormats: {
    ddmmyyyy: "DD/MM/YYYY",
    mmddyyyy: "MM/DD/YYYY",
    yyyymmdd: "YYYY/MM/DD",
    yyyyMMDD: "yyyy-MM-DD",
    hhmmss: "hh:mm:ss",
    hhmmssa: "hh:mm:ss A",
    ddmmyyyyhhmmss: "dd/MM/yyyy HH:mm:ss",
    mmddyyyyHHmmss: "MM/DD/YYYY HH:mm:ss",
    DDMMyyyyHHmmss_Dash: "DD/MM/yyyy HH:mm:ssZ A",
    mmddyyyy_hhmmA: "MM/DD/YYYY HH:mm:ss A",
    mmmm_ddyy: "MMMM DD, YYYY",
    YYYYMMDD: "YYYY-MM-DD",
    dddd_Do_mmmm: "dddd Do MMMM",
    ddd_Do_mmmm_yy: "ddd Do MMM  yy",
    Do_MMMM_YYYY: "Do MMMM YYYY",
    HHmmss: "HH:mm:ss",
    MMddyyyy: "MM/dd/yyyy",
    ddMMyyyy: "dd/MM/yyyy",
    MMMMdd: "MMMM DD",
    ddd_MMMM_D_yy : "ddd MMMM Do yy",
    dddd_Do_mmmmm_yy: "dddd Do MMMM YYYY",
    ddMMYY:"DD/MM/YY",
    ddmmyyhhmmss: "dd/MM/yy HH:mm:ss",
    YYYYDDMMHHmmss_Dash: "YYYY-MM-DD HH:mm A",
    HHMM_Dash : "hh:mm A",
    DoMMMMYYYY : "Do MMMM YYYY",
    hhmmsssmalla: "hh:mm:ss a",
    yyyymmddhhmmss: "YYYY/MM/DD HH:mm:ss",
    hmmA:"h:mm A",
    DDMMYYHHmmss:"DD/MM/YY HH:mm:ss",
    hhmmAddddDoMMMMYYYY:"hh:mm A dddd Do MMMM YYYY",
  },
  alphaNumericPattern: /^[a-zA-Z0-9\-\s]+$/i,
  pdfPattern: /\.(pdf)$/,
  emailPattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  imagePattern: /\.(jpg|jpeg|png)$/,
  imagePdfPattern: /\.(jpg|jpeg|png|pdf)$/,
  dEANumberPattern: /^[A-Z][A-Z9][0-9]{7}(-[A-Z0-9]{1,7})?$/,
  notificationTimeout: 5000,
  notificationLocationTimeout: 1000,
  notificationUserTimeout: 2000,
  defaultCurrencySymbol: "$",
  defaultCurrencyCode: "USD",
  csvPattern: /\.(csv)$/,
  decimalRegex: /^\d{1,3}(\.\d{1,1})?$/, // Max length should be 3 numbers before decimal and 1 digits after decimal,
  charactersWithoutSpace: /^\S*$/,
  tabs: {
    widgetMain: "WidgetMain",
    serviceCategory: "ServiceCategory",
    facility: "Facility",
    location: "Location",
    service: "Service",
    serviceProvider: "ServiceProvider",
    note: "Note",
    medicalConsent: "MedicalConsent",
    bookingConfirmation: "BookingConfirmation",
    bookingConfirm: "BookingConfirm",
    bookingSummary: "BookingSummary",
    cardList: "CardList",
    membershipCredit: "MembershipCredit",
    membershipService: "MembershipService",
    signUp: "WidgetSignup",
    consent: "WidgetConsent",
    widgetverifyBusiness: "WidgetVerifyBusiness",
    dummyBooking: "dummyBooking",
    assignClients: "assignClients",
  },
  consultationTab:{
    hcp : "hcp",
    consultationSummary: "ConsultationSummary",
    consultationConfirmation: "ConsultationConfirmation",
  },
  bookingChoiceDetail: {
    mobileCalloutService: "MobileCalloutService",
    facilityService: "FacilityService",
  },
  perfectScrollbarDetail: {
    perfectScrollbarOptions: {
      wheelSpeed: 2,
      wheelPropagation: false,
      suppressScrollX: false,
    },
    className: "scroll cm-flex-1",
    styleDetail: {
      // height: "100vh",
      // maxHeight: "65vh",
      position: "relative",
    },
  },
  bookingMinInterval: 15,
  priceFloatingPoints: 2,
  ratingFloatingPoints: 1,
  latLongRoundFraction: 4,
  media: {
    defaultprofilepic: "defaultprofilepic.png",
    docPic: "doc.jpg",
    mastercard: "mastercard.png",
    visa: "visa.png",
    americanexpress: "americanexpress.png",
    card: "card.png",
    defaultUserPic: "default_user.png",
    appstore:"app-store.png",
    googleplay:"google-play.png",
    checkmark:"checkmark.png",
    radiooutline:"radio-outline.png",
    medicalconsent:"medical-consent.png",
    facilities:"facilities.png",
    questionMarkLogo:"QuestionMarkLogo.png",
    noclient:"noclient.png",
    travelFessIcon:"travelFessIcon.png",
    bookingTypeBg:"BookingTypeBg.png",
    servicetypedefault:"servicetype_default.png",
    serviceForms:"serviceForms.png",
    mute:"mute.png",
    unmute:"unmute.png",
    end:"end.png",
    videoOff:"videoOff.png",
    videoOn:"videoOn.png",
    waiting:"waiting.gif",
    clubReadyIcon:"clubReadyIcon.png",
    hourglass:"hourglass.png",
  },
  service: {
    RequiresMedicalConsulatation: "Requires medical consultations",
    AvailAddonsMessage: "Please select atleast one service to avail addons",
  },
  moduleName: {
    serviceProvider: "Service Provider",
    location: "Location",
    promoCode: "Promo Code",
    facility: "Facility",
    hcp: "HCP",
  },
  user: {
    gender: [
      { label: "Male", value: "M" },
      { label: "Female", value: "F" },
      { label: "Other", value: "O" },
    ],
  },
  userType: {
    MasterAdmin: "Master Admin",
    MD: "MD",
    Admin: "Admin",
    Client: "Client",
    Nurse: "Nurse",
    SuperAdmin: "Super Admin",
    Doctor: "Doctor",
    serviceProvider: "Service Provider",
    hcp: "HCP",
    InsurancePartner: "Insurance Partner",
    Receptionist: "Receptionist",
    Business: "Business",
  },
  consentTreatmentType: {
    medicalConsent: "Medical Consent",
  },
  sequenceData: {
    Credit: "Credit",
    Coupon: "Coupon",
  },
  membershipDiscountType: {
    Credit: "Credit",
    Service: "Service",
  },
  membershipShowBlock : {
    Credit: "Membership - Credit",
    Service: "Membership - Service",
  },
  paymentType : {
    CASH: "Cash",
    CREDITCARD: "CreditCard",
    GIFTCARD: "GiftCard",
    INSURANCE: "Insurance",
    MEMBERSHIP: "Membership"
  },
  membershipCredits: "credits",
  membershipServices: "services",
  hours: "hours",
  minutes:"minutes",
  FacilityBooking: "Facility Booking",
  CalloutBooking: "Client Booking",
  PreBooking: "Pre Booking",
  SERVICE: "Service",
  MedicalConsentUpdated: "Medical Consent Updated Successfully",
  MedicalConsentAdded: "Medical Consent Added Successfully",
  dripbarMedicalConsentUpdated: "Medical History Updated Successfully",
  dripbarMedicalConsentAdded: "Medical History Added Successfully",
  Exception: "Exception was thrown by handler",
  ServerValidation: "server.validation",
  ExceptionMsg:
    "server failed to fulfill a valid request due to an error with server",
  CreditBalanceValidation: "Please enter credit same or less than total credit",
  CreditPaymentValidation: "Please enter credit same or less than total paying amount",
  DiscountIsGreater : "The selected service amount must be less than or equal to remaining paying amount.",
  ServiceValidation : "I'm sorry, but this service provider is unable to cover that specific area. Please enter and select an alternate location.",
  ServiceNotFoundValidation : "Service not found.",
  Error404Page : "/widget/404",
  Error401Page : "/widget/401",
  ConsentSignature : "Signature is mandatory in order to proceed.",
  Signature: "Signature",
  patientsignature: "PATIENT SIGNATURE",
  afterSaveSequenceData: {
    Credit: "MembershipCredit",
    Service: "MembershipService",
    Coupon: "SpecificTypePromoCouponDiscount",
    GroupDiscount: "GroupDiscount",
    RewardClubDiscount: "RewardClubDiscount",
    AllTypePromoCouponDiscount: "AllTypePromoCouponDiscount",
    MembershipCreditOnRemaining: "MembershipCreditOnRemaining",
    MembershipServiceOnRemaining: "MembershipServiceOnRemaining",
    ReferralCodeDiscount: "ReferralCodeDiscount",
    ReferralCouponDiscount: "ReferralCouponDiscount",
    ConsultationCost: "ConsultationCost",
  },
  HipaaConsentForImages: {
    HeaderTitle: "Authorization For And Release Of Medical Photographs",
    ContentText1:
      "Aesthetic treatments and various medical treatments are a visually oriented specialty. As such it is necessary that medical photographs be taken before, during and after a surgical procedure or treatment. Similar to other imaging techniques like x-rays or CT scans, this allows for proper planning before procedures and follow up evaluation afterward.",
    ContentText2:
      "Photographs are required only for the body part in question. This means that unless the planned treatment is on the face or head itself, the images typically do not include the face. Consent is required to take such images . Additionally. patients may consent to release these medical photographs/slides, and videotapes for a stated purpose such as for use in instructional, educational, or promotional materials.",
    ContentText2Title: "Consent To Take Photographs/Slides/VideoTapes",
    ContentText3:
      "I hereby authorize {0} and its associates or licensees to take pre-procedural, and post-procedural photographs, slides, and/or videotapes.",
    ContentText4:
      "I consent to the use of these images for the purposes of pre-procedural planning and post-procedural evaluation and I understand that they shall be made a part of my medical record.",
    Signature: "Please sign below(Patient Signature):",
    AddSignature: "Add Signature",
    Date: "Date:",
  },
  bookingStatusTypeList: {
    INSESSION: "Session In Progress",
    ACCEPTED: "Booking Accepted",
    COMPLETED: "Completed",
    CANCELLED: "Booking Cancelled",
    INCOMPLETED: "Incomplete",
    REFUNDREQUESTED: "Refund Requested",
    PARTIALLYREFUNDED: "Partially Refunded",
    DUMMYSERVICEBOOKING: "Dummy Service Booking Requested"
  }, 
  bookingStatusList: [
    {
      BookingStatusId: 1,
      BookingStatusValue: "BookingAccepted",
      BookingStatus: "Booking Accepted",
      BookingName: "Upcoming",
      BookingNameFilter: "Upcoming",
    },
    {
      BookingStatusId: 3,
      BookingStatusValue: "Completed",
      BookingStatus: "Completed",
      BookingName: "Completed",
      BookingNameFilter: "Completed",
    },
    {
      BookingStatusId: 4,
      BookingStatusValue: "BookingCancelled",
      BookingStatus: "Booking Cancelled",
      BookingName: "Cancelled",
      BookingNameFilter: "Cancelled",
    }

  ],
  bookingChoiceList: [
    {
      bookingTypeId: 1,
      bookingTypeValue: "Callout",
      basedOn: "Callout",
    },
    {
      bookingTypeId: 2,
      bookingTypeValue: "Facility",
      basedOn: "Facility",
    },
    {
      bookingTypeId: 3,
      bookingTypeValue: "Patient Specific Booking",
      basedOn: "Patient Specific Booking",
    },
  ],
  StatusType: [
    {
      BookingStatusValue: "BookingAccepted",
      BookingStatus: "Booking Accepted",
      BookingName: "Upcoming",
    },
    {
      BookingStatusValue: "Completed",
      BookingStatus: "Completed",
      BookingName: "Completed",
    },
    {
      BookingStatusValue: "BookingCancelled",
      BookingStatus: "Booking Cancelled",
      BookingName: "Cancelled",
    },
    {
      BookingStatusValue: "Incomplete",
      BookingStatus: "Incomplete",
      BookingName: "Incomplete",
    },
    {
      BookingStatusValue: "SessionInProgress",
      BookingStatus: "Session In Progress",
      BookingName: "In-Progress",
    },
  ],
  bookingListStatus: {
    ACCEPTED: "Booking Accepted",
    COMPLETED: "Completed",
    CANCELLED: "Booking Cancelled",
  },
  statusList: {
    Upcoming: "Upcoming",
    Completed: "Completed",
    Cancelled: "Cancelled",
    InComplete: "Incomplete",
    InProgress: "In-Progress",
    Scheduled:"Scheduled",
    Lapsed:"Lapsed",
    Pending:"Pending",
    InCompleteWithDash: "In-Complete",
    Unapproved:"Unapproved",
    Approved: "Approved",
  },
  ZipCodeValidation: "server.validation.ZipcodeNotAccociatedWithAdminReceptionistBusiness",
  notificationServiceZipCodeTimeout: 4000,
  clientAccountNotFound: "You do not have any active role as a Client.",
  clientCancellationType : {
  OutSideCancellationPolicyHour: "Outside Cancellation Policy Hour",
  InSideCancellationPolicyHour: "Inside Cancellation Policy Hour",
  NoCharge: "No Charge",
  },
  callOutBookingChoice : "Book a Mobile Service",
  bookingFrom : {
    widget : "Widget",
    mobileMedIV : "Mobile Med Iv"
  },
  timingStatus:{
    PRESESSION: "Pre Session",
	  INSESSION: "In Session",
	  INCOMPLETE: "InComplete",
	  COMPLETED: "Completed",
      CANCELLED: "Cancelled",
  },
  mediaType: {
    client: 48,
    profileImage: 11,
  },
  baseFolder: {
     client: "Client",
     profileImage: "ProfileImage",
  },
  pdfFileSize: "5242880", // 5 MB
  imageFileSize: "2097152", // 2 MB
  formType: {
    READONLY: "Read Only",
    FORM: "Form"
  },
  messageConsultationType : {
    SERVICE: "Service",
    MEDICAL: "Medical",
    MEDICAL_SCREENINNG: "MedicalScreening"
  },
  serviceConsultaionClientStatusData : [
    {
      statusId: 1,
      statusValue: "Scheduled",
      displayStatus: "Upcoming",
      filterStatus: "Upcoming"
    },
    {
      statusId: 3,
      statusValue: "Completed",
      displayStatus: "Completed",
      filterStatus: "Completed"
    },
    {
      statusId: 5,
      statusValue: "Cancelled",
      displayStatus: "Cancelled",
      filterStatus: "Cancelled"
    },    
    {
      statusId: 4,
      statusValue: "InComplete",
      displayStatus: "InComplete",
      filterStatus: "InComplete"
    },
    {
      statusId: 6,
      statusValue: "Pending",
      displayStatus: "Pending",
      filterStatus: "Pending"
    },
    {
      statusId: 7,
      statusValue: "Lapsed",
      displayStatus: "Lapsed",
      filterStatus: "Lapsed"
    },
  ],
  consultTypeList : [
    {
      consultTypeId: 1,
      consultTypeValue: "Service Consult",
      consultTypeFilter: "Service Consult"
    },
    {
      consultTypeId: 2,
      consultTypeValue: "InSession Consult",
      consultTypeFilter: "In-session Service Consult"
    }
  ],
  consultationData :{
    club_ready : "club ready",
    client:"Client",
    service:"Service",
    clubReady:"clubReady",
    scheduleDateTimeInUTC:"scheduleDateTimeInUTC"
  },
  bookingsData : {
    bookingUTCDateTime:"bookingUTCDateTime"
  },
  reviewCount : 3,
  symbols : {
    hashtag: "#",
    asterisk: "*",
    comma: ",",
    dash: "-",
    dotSpace: ". ",
    orSpace: " | ",
    space: " ",
    commaSpace: ", ",
    percentage: "%",
    doller: "$"
  },
  serviceConsultationType : {
    serviceConsult:"serviceConsult",
    inSessionConsult:"inSessionConsult"
  },
  Authorized:"Authorized",
  preAuthorizeStatusVoid: "Void",
  bookingStatusData : [
    {
      BookingStatusId: 1,
      BookingStatusValue: "BookingAccepted",
      BookingStatus: "Booking Accepted",
      BookingName: "Upcoming",
      BookingNameFilter: "Upcoming"
    },
    {
      BookingStatusId: 2,
      BookingStatusValue: "SessionInProgress",
      BookingStatus: "Session In Progress",
      BookingName: "In-Progress",
      BookingNameFilter: "In Session"
    },
    {
      BookingStatusId: 3,
      BookingStatusValue: "Completed",
      BookingStatus: "Completed",
      BookingName: "Completed",
      BookingNameFilter: "Completed"
    },
    {
      BookingStatusId: 4,
      BookingStatusValue: "BookingCancelled",
      BookingStatus: "Booking Cancelled",
      BookingName: "Cancelled",
      BookingNameFilter: "Cancelled"
    },
    {
      BookingStatusId: 5,
      BookingStatusValue: "InComplete",
      BookingStatus: "Incomplete",
      BookingName: "InComplete",
      BookingNameFilter: "InComplete"
    },
  ],
  cardType:{
    Visa: "Visa",
    MasterCard: "MasterCard",
    AmericanExpress: "AmericanExpress"
  },
  serviceTimerKeyName : {
    ServiceConsultStageOne: "SERVICECONSULT_STAGE1_INTERVAL_MINUTES",
    ServiceConsultStageSecond: "SERVICECONSULT_STAGE2_INTERVAL_MINUTES",
  }, 
  dripbar : "DRIPBaR",
  nadinformedconsent:"NADINFORMEDCONSENT",

  semaGluTideUrl : "https://www.cdc.gov/reproductivehealth/contraception/mmwr/spr/notpregnant.html",
  
  genderDataList : [
    {
      genderId: 1,
      label: "Male",
      value: "M"
    },
    {
      genderId: 2,
      label: "Female",
      value: "F"
    },
    {
      genderId: 3,
      label: "Other",
      value: "O"
    },
  ],
  prefix: [
    { label: "Dr.", value: "Dr." },
    { label: "Mr.", value: "Mr." },
    { label: "Mrs.", value: "Mrs." },
    { label: "Ms.", value: "Ms." },
    { label: "Miss.", value: "Miss." },
  ],
   heightUnits : [
    {
      label: "in",
      value: "in"
    },
    {
      label: "cm",
      value: "cm"
    }
  ],
  zipCodeDefaultValues: {
    zipCodePattern: /^\d{5}(-\d{4})?$/,
    zipCodeMaxLength: 10
  },
  defaultCountry: "United States",
   weightUnits :[
    {
      label: "lb",
      weightValue: "lb"
    },
    {
      label: "kg",
      weightValue: "kg"
    }
  ]
};