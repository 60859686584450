import React from "react";
import { LoadingDialog } from "../../_metronic/_partials/controls";
import { Spinner } from "react-bootstrap";
export function ActionLoadingDialog({ isLoading }) {
  return (
    <div className="cm-loader">
      
      {isLoading &&
        <div id="splash-screen" className="kt-splash-screen">
          <svg className="splash-spinner" viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
          </svg>
        </div>
        //<LoadingDialog isLoading={isLoading} text="Loading ..." />
      }
    </div>
  );
}
