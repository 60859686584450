import { DataConstant } from "../../../constants/dataConstant";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { getCurrentDate } from "../../../../utils/helper";
export const actionTypes = {
  SetHcpDetail: "[Set Hcp] Action",
  SetHcpTab: "[Set HcpTab] Action",
  SetConsultationSummary: "[Set ConsultationSummary] Action",
  setRescheduleConsultation: "[Set ResceduleConsultationSummary]",
  setClearConsultationDetail: "[Set ClearConsultationDetail]",
    SetConsultationSummaryDetail: "[Set ConsultationSummaryDetail] Action",
    setServiceConsultScheduleDateTimeInUTC: "[Set ServiceConsultScheduleDateTimeInUTC]"
};

export const initialConsultationState = {
  selectedConsultationBookingDate: getCurrentDate(new Date()),
  isConsultationOtherTimeSlotRedirect: false,
  currentTab: DataConstant.consultationTab.hcp,
  hcpDetail: {
    userDetailId: 0,
    name: "",
    profileImage: "",
    workingDateTime: "",
    workingTimeFormat: "",
    selectedTimeSlot: "",
    bookingTime: "",
    selectedTimeSlotIndex: "",
    timeZoneName: '',
    timeZoneShortName: '',
    slots: [],
    userId: 0,
    defaultBusinessId: 0
  },
  consultationSummary: {
    userServiceConsultRequestId: 0
  },
  resceduleConsultation: {
    isReschedule: false,
    userServiceConsultRequestId: 0,
    serviceDetails: [],
    bookingUTCDateTime: "",
    userBookingId: 0,
    clientUserDetailId: 0
  },
  consultationSummaryDetail: {
    serviceWiseConsultationFees: 0,
    cancellationNoPenaltyUptoHrs: 0,
    cancelledBookingChargedPercentage: '',
    totalConsultationAmount: 0
    },
  scheduleDateTimeInUTC: ""
};

export const consultationReducer = persistReducer(
  {
    storage,
    key: "consultation-detail",
  },
  (state = initialConsultationState, action) => {
    switch (action.type) {
      case actionTypes.SetHcpDetail: {
        const { hcpDetail, selectedConsultationBookingDate, isConsultationOtherTimeSlotRedirect } = action.payload;
        return { ...state, hcpDetail: hcpDetail, selectedConsultationBookingDate: selectedConsultationBookingDate, isConsultationOtherTimeSlotRedirect: isConsultationOtherTimeSlotRedirect };
      };
      case actionTypes.SetHcpTab: {
        const { currentTab } = action.payload;
        return { ...state, currentTab };
      }
      case actionTypes.SetConsultationSummary: {
        const { consultationSummary } = action.payload;
        return { ...state, consultationSummary: consultationSummary };
      }
      case actionTypes.setRescheduleConsultation: {
        const { resceduleConsultation } = action.payload;
        return { ...state, resceduleConsultation: resceduleConsultation };
      }
      case actionTypes.setClearConsultationDetail: {
        return {
          ...state, selectedConsultationBookingDate: initialConsultationState.selectedConsultationBookingDate, isConsultationOtherTimeSlotRedirect: initialConsultationState.isConsultationOtherTimeSlotRedirect, currentTab: initialConsultationState.currentTab,
          hcpDetail: initialConsultationState.hcpDetail, consultationSummary: initialConsultationState.consultationSummary, resceduleConsultation: initialConsultationState.resceduleConsultation,
          consultationSummaryDetail : initialConsultationState.consultationSummaryDetail
        };
      }
      case actionTypes.SetConsultationSummaryDetail: {
        const { consultationSummary } = action.payload;
        return { ...state, consultationSummaryDetail: consultationSummary };
      }
      case actionTypes.setServiceConsultScheduleDateTimeInUTC: {
        const { scheduleDateTimeInUTC } = action.payload;
        return { ...state, scheduleDateTimeInUTC  : scheduleDateTimeInUTC };
      }
      default:
        return state;
    }
  });

